import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { sendRehearsalEmail } from '../app/api';
import { base } from '../app/backend';
import { Portal } from '../app/utils';
import Button from '../components/Button';
import Form from '../components/Form';
import Modal from '../components/Modal';

const Panel = {
  Accept: 1,
  Decline: 2,
  Error: 10,
};

export default function AdminPage({ data, location }) {
  const { register, handleSubmit, setValue } = useForm();
  const { heroImage } = data;
  const [record, setRecord] = useState<any>();
  const [error, setError] = useState(false);
  const [panel, setPanel] = useState(null);
  const [loading, setLoading] = useState<any>();

  useEffect(() => {
    if (!location.search) return;
    let params = new URLSearchParams(location.search);
    setLoading('Getting things ready');
    let minLoadingTime = Date.now() + 3000;
    let next = () => setLoading(null);
    if (params.get('email')) {
      let email = decodeURIComponent(params.get('email'));
      base('Rehearsal Dinner')
        .select({
          filterByFormula: `(FIND("${email}", {email address}))`,
        })
        .firstPage()
        .then(r => {
          if (r.length < 1) return;
          setRecord(r[0]);
        })
        .catch(console.error)
        .then(() => {
          let diff = minLoadingTime - Date.now();
          if (diff < 0) return next();
          setTimeout(next, diff);
        });
    }
  }, []);

  function onSubmit(data) {
    base('Rehearsal Dinner')
      .select({
        filterByFormula: `(FIND("${data.email}", {email address}))`,
      })
      .firstPage()
      .then(r => {
        if (r.length < 1) throw new Error('No results found');
        console.log(r);
        setRecord(r[0]);
      })
      .catch(e => {
        setError(true);
        console.error(e);
      });
  }

  function onRsvp(data) {
    setLoading('Sending RSVP');
    let minLoadingTime = Date.now() + 3000;
    let next = () => {
      setLoading(false);
      setPanel(data.attend === 'true' ? Panel.Accept : Panel.Decline);
    };
    base('Rehearsal Dinner')
      .update([
        {
          id: record.id,
          fields: {
            responded: true,
            'rsvp count': data.attend === 'false' ? 0 : 1 + (data.plus1 ? 1 : 0),
          },
        },
      ])
      .then(() =>
        sendRehearsalEmail({
          name: record.fields['guest name'][0],
          email: record.fields['email address'][0],
        })
      )
      .catch(e => {
        console.error(e);
        next = () => {
          setLoading(null);
          setPanel(Panel.Error);
        };
      })
      .then(() => {
        let diff = minLoadingTime - Date.now();
        if (diff < 0) return next();
        setTimeout(next, diff);
      });
  }

  return (
    <article data-theme="form">
      <div className="hero">
        <Img
          imgStyle={{ objectPosition: 'right 21%' }}
          className="hero-image"
          fluid={heroImage.childImageSharp.fluid}
        />
      </div>
      {loading && (
        <Portal>
          <div data-theme="form" className="loadingContainer">
            <div className="lds-heart">
              <div></div>
            </div>
            <p>{loading}</p>
          </div>
        </Portal>
      )}
      {!record && !loading && (
        <Modal open>
          <Modal.Content>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <h2>Rehearsal RSVP</h2>
              {error && (
                <p className="message" data-error>
                  We couldn't find a guest with that email
                </p>
              )}
              <Form.Input inputRef={register} name="email" label="Email" />
              <Modal.Actions>
                <Button primary content="Next" />
              </Modal.Actions>
            </Form>
          </Modal.Content>
        </Modal>
      )}
      {record && !panel && (
        <Modal open>
          <Modal.Content>
            <Form onSubmit={handleSubmit(onRsvp)}>
              <h2>Hi {record.fields['guest name'][0]}</h2>
              <p>Are you planning on attending the rehearsal?</p>
              {record.fields['count'][0] > 1 && (
                <Form.Checkbox inputRef={register} name="plus1" label="Bringing +1" />
              )}
              <Modal.Actions>
                <Button
                  primary
                  onClick={() => setValue('attend', true)}
                  name="attend"
                  inputRef={register}
                  value="attend"
                  content="Attend"
                />
                <Button
                  inputRef={register}
                  onClick={() => setValue('attend', false)}
                  name="attend"
                  value="decline"
                  content="Decline"
                />
              </Modal.Actions>
            </Form>
          </Modal.Content>
        </Modal>
      )}
      {panel === Panel.Accept && (
        <Modal open>
          <Modal.Content>
            <h2>Thanks!</h2>
            <p>We'll see you there!</p>
          </Modal.Content>
        </Modal>
      )}
      {panel === Panel.Decline && (
        <Modal open>
          <Modal.Content>
            <h2>Thanks for the RSVP</h2>
            <p>Sorry you couldn't make it</p>
          </Modal.Content>
        </Modal>
      )}
      {panel === Panel.Error && (
        <Modal open>
          <Modal.Content>
            <h2>Oh no!</h2>
            <p>
              Something went wrong and we were unable to save your rsvp
              <br />
              Please try again in a few minutes
            </p>
          </Modal.Content>
        </Modal>
      )}
    </article>
  );
}

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "practiceHero.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
